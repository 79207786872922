<template>
  <div class="w-80 mx-auto">
    <div>
      <div id="promptpay" class="rounded-2xl overflow-hidden border">
        <div class="py-1 px-5 flex justify-center items-center" style="background-color: #0E3E68;">
          <img src="/images/promptpay/thai-payment-logo.png" width="100">
        </div>

        <div class="p-2">
          <div class="mb-4">
            <img
              class="mx-auto"
              src="/images/promptpay/promptpay-logo.png"
              alt="qr promptpay"
              width="80"
            >

            <img
              class="mx-auto mb-4"
              width="200"
              height="200"
              :src="promptpayQrcodeImage"
              alt="qr promptpay"
            >

            <p class="text-center mt-1">ชื่อบัญชี: {{ props.fullName }}</p>

            <p class="text-center">หมายเลขพร้อมเพย์: {{ props.promptpayId }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-4">
      
       <button
        class="py-1.5 px-3 m-1 text-center bg-white border rounded-md text-dark hover:bg-violet-500 hover:text-gray-100 "
        @click="clickDownloadPromptpay"
      >
        Download
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import html2Canvas from 'html2canvas'
import qrcode from 'qrcode'
import promptpayQr from 'promptpay-qr'



const props = defineProps({
  promptpayId: String,
  fullName: String
})

const promptpayQrcodeImage = ref('')

const generatePromptpayQrcode = async () => {
  const payload = promptpayQr(props.promptpayId, {  })
  const base64 = await qrcode.toDataURL(payload)
  promptpayQrcodeImage.value = base64
}

watchEffect(()=>{
    if(props.promptpayId){
        generatePromptpayQrcode()
    }
})



// handle click
const clickDownloadPromptpay = async () => {
  const canvas = await html2Canvas(document.getElementById('promptpay'), { useCORS: true })
  const myImage = canvas.toDataURL()
  downloadUri(myImage, 'romptpay-qrcode.png')

  function downloadUri(uri: string, name: string) {
    const link = document.createElement('a')
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
  }
}
</script>